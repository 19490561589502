import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import Button from "Components/Button";
import Dropdown from "Components/Dropdown";
import InputField from "Components/fields/InputField";
import CheckboxField from "Components/fields/CheckboxField";
import Label from "Components/fields/Label";
import Loading from "Components/Loading";
import ButtonWrapper from "Components/ButtonWrapper";
import Heading6 from "Components/styleguide/Heading6";
import AlertIcon from "Icons/AlertIcon";

const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  &.add-form {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(152, 160, 171, 0.4);
    margin-bottom: 16px;
  }
  .description {
    font-size: 15px;
    color: #4b6180;
    margin-bottom: 24px;
  }
  .warning {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    color: #38485e;
    svg {
      margin-right: 24px;
    }
  }
`;

const AccessFormLayout = styled.form`
  padding: 32px;
  width: 100%;
  box-sizing: border-box;
  .field:last-child .field {
    margin-bottom: 0;
  }
`;

const InputLayout = styled.div`
  display: flex;
  align-items: center;
`;

const EnvironmentPermissions = styled.div`
  margin-bottom: 24px;
  hr {
    margin: 0;
  }
`;

const PermissionWrapper = styled.div`
  &.environment-permission {
    background: transparent;
    padding: 0;
    label {
      margin-bottom: 8px;
    }
    .dropdown > div {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &.project-permission {
    padding: 0;
    line-height: 56px;
    width: 100%;
    box-sizing: border-box;
    .field {
      margin-bottom: 0;
      line-height: 56px;
    }
    label {
      margin: 0;
      font-size: 15px;
      font-weight: normal;
      height: 56px;
      line-height: 56px;
    }
    .dropdown {
      width: 100%;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .select-box {
          min-width: 33%;
        }
        .select-box__control {
          background: transparent;
          width: 164px;
          border-color: transparent;
          margin-left: -16px;
        }
        .select-box__menu {
          margin-left: -16px;
        }
      }
    }
  }
`;

const LoadingWrapper = styled.div`
  background: #f7f7f7;
  padding: 0 16px;
  line-height: 42px;
  margin-bottom: 32px;
`;

const EnvironmentHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  label + label {
    width: 33%;
  }
`;

class AccessForm extends React.Component {
  constructor(props) {
    super(props);
    this.onPermissionChange = this.onPermissionChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onSuperUserChange = this.onSuperUserChange.bind(this);
    this.onAgreementChange = this.onAgreementChange.bind(this);
    // this.getAccessDefault = this.getAccessDefault.bind(this);
    this.getAccessOptions = this.getAccessOptions.bind(this);
    this.accessOptions = [
      { value: "no_access", label: "no access" },
      { value: "admin", label: "admin" },
      { value: "viewer", label: "viewer" },
      { value: "contributor", label: "contributor" }
    ];

    this.defaultAccessValue = this.props.environmentForm
      ? "viewer"
      : "No access";

    this.state = {
      userId: this.props.userId,
      errors: {},
      accesses: {},
      superUser: this.props.superUser,
      isChanged: false,
      showSuperUserToggle:
        typeof this.props.userId !== undefined || this.props.superUser === true
    };
  }

  componentDidMount() {
    const accesses = this.props.accesses;
    if (this.props.environmentForm && !this.props.userId) {
      const environment = this.props.environments[0].name;
      accesses[environment].role = "viewer";
    }
    this.setState({ accesses });
  }

  onPermissionChange(environmentId, value) {
    this.setState(prevState => {
      const newState = prevState;

      newState.isChanged = true;
      newState.accesses[environmentId] = {
        id: this.props.accesses[environmentId].id,
        role: value
      };
      return newState;
    });
  }

  onSuperUserChange(event) {
    this.setState({
      superUser: event.target.checked,
      isChanged: true
    });

    this.props.environments.forEach(environment => {
      let role;
      // If this isn't a new access populate the form with the
      // previous role from the state.
      if (this.props.userId && this.props.accesses[environment.id]) {
        role = this.props.accesses[environment.id.role];
      }

      this.onPermissionChange(
        environment.id,
        event.target.checked ? "admin" : role
      );
    });
  }

  onEmailChange(event) {
    this.setState({
      email: event.target.value,
      isChanged: true
    });
  }

  onAgreementChange(event) {
    this.setState({
      agreement: event.target.checked,
      isChanged: true
    });
  }

  onSave() {
    if (!this.props.isNew || (this.state.agreement && this.state.email)) {
      //I've got only the state that the user change
      // Get it so the current state of the form gets passed into this, not just the changes.
      this.props.onSave(this.state);
    }
  }

  onSubmit(event) {
    event.preventDefault();
  }

  getAccessOptions() {
    return this.accessOptions.filter(option => {
      if (this.props.environmentForm === true && option.value === "no_access") {
        return false;
      }
      return true;
    });
  }

  render() {
    const {
      environments,
      intl,
      enabled,
      isLoading,
      onDelete,
      deletePermission,
      accessesAreLoading,
      environmentForm,
      isNew
    } = this.props;

    return (
      <Layout className={isNew ? "add-form" : ""}>
        <LiveMessage
          message={
            this.props.userId
              ? intl.formatMessage({ id: "edit_user" })
              : intl.formatMessage({ id: "add_user" })
          }
          aria-live="polite"
        />
        <AccessFormLayout
          id={
            environmentForm
              ? isNew
                ? "environment-acess-add-form"
                : "environment-access-edit-form"
              : isNew
                ? "project-access-add-form"
                : "project-access-edit-form"
          }
          aria-labelledby="add-user-heading"
          onSubmit={this.onSubmit}
        >
          {!this.props.userId &&
            environmentForm && (
              <p className="description">
                Add a new user to your environment and assign their permission
                level.
              </p>
            )}
          {!this.props.userId &&
            !environmentForm && (
              <p className="description">
                Add a new user to your project and assign their permission
                levels for each environment.
              </p>
            )}
          {this.props.userId &&
            environmentForm && (
              <div className="description">
                Changing environment permissions will trigger a redeploy of the
                current environment.
              </div>
            )}
          <InputLayout>
            {!this.props.userId && (
              <div>
                <InputField
                  id="user-email"
                  placeholder="Email"
                  label="email"
                  value={this.state.email || ""}
                  onChange={this.onEmailChange}
                  error={this.props.errors.email}
                  disabled={!enabled}
                  aria-disabled={!enabled}
                  ariaLabel={intl.formatMessage({ id: "user_email" })}
                />
              </div>
            )}
          </InputLayout>
          {this.state.showSuperUserToggle &&
            !environmentForm && (
              <InputLayout className="field">
                <CheckboxField
                  label={`${intl.formatMessage({ id: "project_admin" })}`}
                  type="checkbox"
                  value={this.state.superUser}
                  onChange={this.onSuperUserChange}
                  error={this.props.errors.su}
                  disabled={!enabled}
                  aria-disabled={!enabled}
                  forId="super-user"
                />
              </InputLayout>
            )}
          {!this.state.superUser &&
            (accessesAreLoading ? (
              <LoadingWrapper>
                <Loading loadType="environments" />
              </LoadingWrapper>
            ) : (
              <EnvironmentPermissions className="environment-permissions">
                <React.Fragment>
                  {!environmentForm && (
                    <React.Fragment>
                      <Heading6>Environment permissions</Heading6>
                      <EnvironmentHeaders className="environment-headers">
                        <Label>Environment</Label>
                        <Label>Permission type</Label>
                      </EnvironmentHeaders>
                      <hr className="full-width" />
                    </React.Fragment>
                  )}
                  {environments.map(environment => (
                    <div key={environment.id}>
                      {environment.status !== "inactive" && (
                        <PermissionWrapper
                          className={`${
                            environmentForm
                              ? "environment-permission"
                              : "project-permission"
                          }`}
                        >
                          <InputLayout className="field" key={environment.id}>
                            <Dropdown
                              label={`${
                                environments.length !== 1
                                  ? environment.name
                                  : "Permission type"
                              }`}
                              options={this.getAccessOptions()}
                              onChange={({ value }) =>
                                this.onPermissionChange(environment.id, value)
                              }
                              defaultValue={{
                                value: this.props.accesses[environment.id].role,
                                label: this.props.accesses[environment.id].role
                                  ? this.props.accesses[environment.id].role
                                  : this.defaultAccessValue
                              }}
                              clearable={false}
                              multi={false}
                              error={this.props.errors.role}
                              disabled={!enabled}
                              required={environmentForm}
                              className="inline"
                            />
                          </InputLayout>
                          {!environmentForm && <hr />}
                        </PermissionWrapper>
                      )}
                    </div>
                  ))}
                </React.Fragment>
              </EnvironmentPermissions>
            ))}
          {!this.props.userId ? (
            <InputLayout className="field">
              <CheckboxField
                label={intl.formatMessage({ id: "add_user_agreement" })}
                type="checkbox"
                value={this.state.agreement}
                onChange={this.onAgreementChange}
                error={this.state.errors.agreement}
                forId="accept-agreement"
                required={true}
              />
            </InputLayout>
          ) : (
            enabled &&
            !environmentForm && (
              <React.Fragment>
                <div className="warning">
                  <AlertIcon /> Requires the environments to be redeployed
                  before changes take effect.
                </div>
                <hr />
              </React.Fragment>
            )
          )}
          <React.Fragment>
            {isLoading ? (
              <Loading />
            ) : (
              enabled && (
                <ButtonWrapper>
                  {(this.state.isChanged || this.props.isNew) && (
                    <React.Fragment>
                      <Button
                        id="access-save"
                        type="submit"
                        aria-label={intl.formatMessage({ id: "save" })}
                        onClick={this.onSave}
                      >
                        {intl.formatMessage({ id: "save" })}
                      </Button>
                      <Button
                        id="access-cancel"
                        type="button"
                        className="secondary"
                        aria-label={intl.formatMessage({ id: "cancel" })}
                        onClick={this.props.onCancel}
                      >
                        {intl.formatMessage({ id: "cancel" })}
                      </Button>
                    </React.Fragment>
                  )}

                  {deletePermission && (
                    <Button
                      id="access-delete"
                      className="outline"
                      type="button"
                      aria-label={this.props.intl.formatMessage({
                        id: "delete"
                      })}
                      onClick={onDelete}
                    >
                      {intl.formatMessage({ id: "delete" })}
                    </Button>
                  )}
                </ButtonWrapper>
              )
            )}
          </React.Fragment>
        </AccessFormLayout>
      </Layout>
    );
  }
}

AccessForm.propTypes = {
  environments: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  accesses: PropTypes.object,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  userId: PropTypes.string,
  accessId: PropTypes.string,
  isNew: PropTypes.bool,
  enabled: PropTypes.bool,
  intl: PropTypes.object,
  isLoading: PropTypes.bool,
  deletePermission: PropTypes.bool,
  superUser: PropTypes.bool,
  accessesAreLoading: PropTypes.bool,
  environmentForm: PropTypes.bool
};

export default injectIntl(AccessForm);
