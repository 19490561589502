import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const User = props => {
  return (
    <React.Fragment>
      <span className="user">
        {props.display_name &&
          !props.emailOnly && (
            <span className="display-name">{props.display_name}</span>
          )}
        {props.email && <span className="email">{props.email}</span>}
      </span>
      {props.meta && <span className="role">{props.meta}</span>}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  display_name:
    state.user && state.user.getIn(["data", props.userId], {}).display_name,
  email: state.user && state.user.getIn(["data", props.userId], {}).email
});

User.propTypes = {
  display_name: PropTypes.string,
  emailOnly: PropTypes.bool,
  email: PropTypes.string,
  meta: PropTypes.meta
};

export default connect(mapStateToProps)(User);
