import React from "react";
import PropTypes from "prop-types";

import ContentLayout from "Components/ContentLayout";
import EnvironmentAccessList from "Containers/EnvironmentAccessList";

class EnvironmentAccessesRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { projectId, environmentId, organizationId } = this.props.params;

    return (
      <ContentLayout className="settings">
        <EnvironmentAccessList
          organizationId={organizationId}
          projectId={projectId}
          environmentId={environmentId}
        />
      </ContentLayout>
    );
  }
}

EnvironmentAccessesRoutes.propTypes = {
  params: PropTypes.shape({
    organizationId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    environmentId: PropTypes.string.isRequired
  })
};

export default EnvironmentAccessesRoutes;
