import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import ReactTooltip from "react-tooltip";

import AccessForm from "Components/AccessForm";
import SettingLine from "Components/SettingLine";
import User from "./User";
import Heading2 from "Components/styleguide/Heading2";
import AddLink from "Components/AddLink";
import Loading from "Components/Loading";
import LockIcon from "Icons/LockIcon";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import PageDescription from "Components/PageDescription";

const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  .user {
    width: 75%;
    display: flex;
    flex-align: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .display-name {
      font-weight: bold;
      font-size: 16px;
      width: 50%;
    }
    .email {
      font-weight: normal;
      font-size: 15px;
    }
  }
  .role {
    display: inline-block;
    align-items: center;
    font-size: 13px;
    color: #4a495e;
    font-weight: 600;
    white-space: nowrap;
    &:first-letter {
      text-transform: uppercase;
    }
    &.super-user {
      display: inline-flex;
    }
  }
  .lock-icon {
    height: 24px;
    width: 24px;
    min-width: 24px;
    display: inline-flex;
    align-items: stretch;
    margin-left: 16px;
    svg {
      width: 100%;
    }
  }
`;

class EnvironmentAccessList extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.addNewAccess = this.addNewAccess.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getAccessUserName = this.getAccessUserName.bind(this);
    this.state = {
      accesses: [],
      isModalOpen: false
    };
  }

  componentDidMount() {
    this.props.loadAccesses(this.props.environment);
    this.setState(() => ({
      accesses: [...this.props.accesses.valueSeq().toJS()]
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.environment !== this.props.environment) {
      this.props.loadAccesses(nextProps.environment);
    }
    if (nextProps.accesses !== this.props.accesses) {
      this.setState(() => ({
        accesses: [...nextProps.accesses.valueSeq().toJS()]
      }));
    }
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.accesses[index] = {
        ...(this.state.accesses[index] || this.props.accesses[index]),
        [field]: value
      };

      return nextState;
    });
  }

  save(values) {
    const environmentId = this.props.environment.id;

    this.props.updateAccess(
      this.props.environment,
      values.email,
      values.accesses[environmentId],
      this.props.editedLine
    );
  }

  addNewAccess() {
    this.setState(prevState => {
      const nextState = prevState;
      nextState.isNew = true;
      nextState.accesses.unshift({});
      this.props.editLine(0, true);
      return nextState;
    });
  }

  editLine(index) {
    this.props.editedLine === index || this.props.isNew
      ? this.cancel()
      : this.props.editLine(index);
  }

  cancel() {
    if (this.props.isNew) {
      this.setState(prevState => {
        const nextState = prevState;
        nextState.isNew = false;
        nextState.accesses.splice(0, 1);
        return nextState;
      });
    }
    this.props.cancelUpdateAccess();
  }

  openModal(access) {
    this.setState({
      isModalOpen: true,
      access
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  getAccessUserName() {
    const { access } = this.state;
    if (access) {
      return access.getUser().display_name;
    }
  }

  render() {
    const { environment = {} } = this.props;

    return (
      <div>
        <LiveMessage
          message={`${environment &&
            environment.title} environment-level access settings`}
          aria-live="polite"
        />
        {environment &&
          environment.hasPermission &&
          environment.hasPermission("#manage-access") && (
            <AddLink onClick={this.addNewAccess} />
          )}
        <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
          Access
        </Heading2>
        <PageDescription>
          Configure your users access to specific environments and to their
          child environments.<br />Please note that child environments will not
          be updated until they are redeployed.
        </PageDescription>
        <section aria-labelledby="settings-heading">
          {(this.state.accesses || this.props.accesses.valueSeq()).map(
            (access, index) => {
              return (
                <div key={`${access.id}-${index}-read`}>
                  <SettingLine
                    id={`${access.id}-${index}-read`}
                    info={
                      <InfoLayout>
                        <User userId={access.user} />
                        {access.hasPermission &&
                        access.hasPermission("#edit") ? (
                          <span className="role">{access.role}</span>
                        ) : (
                          <span className="role super-user">
                            Project admin{" "}
                            <span
                              className="lock-icon"
                              data-tip
                              data-for={`${access.id}-${index}-tooltip`}
                            >
                              <LockIcon />
                              <ReactTooltip
                                id={`${access.id}-${index}-tooltip`}
                                place="top"
                                type="dark"
                                effect="solid"
                              >
                                You do not have permission to edit this user
                              </ReactTooltip>
                            </span>
                          </span>
                        )}
                      </InfoLayout>
                    }
                    isEditable={
                      access &&
                      access.hasPermission &&
                      access.hasPermission("#edit")
                    }
                    isNew={!access.id}
                    addNewTitle="Add user"
                    isOpen={this.props.editedLine === index}
                    openText={
                      access &&
                      access.hasPermission &&
                      access.hasPermission("#edit")
                        ? this.props.intl.formatMessage({ id: "edit" })
                        : this.props.intl.formatMessage({ id: "view" })
                    }
                    onClick={() => this.editLine(index)}
                  >
                    {this.props.editedLine === index && (
                      <AccessForm
                        accessId={access.id}
                        userId={access.user}
                        access={access}
                        accesses={{
                          [this.props.environmentId]: access || {}
                        }}
                        environments={[this.props.environment]}
                        key={`${access.id}-${index}-edit`}
                        onSave={this.save}
                        onCancel={this.cancel}
                        onDelete={() => this.openModal(access)}
                        errors={this.props.errors}
                        isNew={this.props.isNew}
                        isLoading={this.props.isUpdateLoading}
                        environmentForm={true}
                        enabled={
                          !access.id ||
                          (access &&
                            access.hasPermission &&
                            access.hasPermission("#edit"))
                        }
                        deletePermission={
                          access.hasPermission && access.hasPermission("#edit")
                        }
                      />
                    )}
                  </SettingLine>
                </div>
              );
            }
          )}
          <ModalConfirmDelete
            isOpen={this.state.isModalOpen || false}
            closeModal={this.closeModal}
            deleteFunction={() => this.props.delete(this.state.access)}
            itemType="user"
            itemName={this.getAccessUserName()}
            itemId={this.state.access && this.state.access.id}
          />
          {this.props.isLoading && <Loading />}
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadAccesses: environment =>
    import("Reducers/environment/access").then(reducer =>
      dispatch(
        reducer.loadAccesses(
          props.organizationId,
          props.projectId,
          props.environmentId,
          environment,
          true
        )
      )
    ),
  updateAccess: (environment, access, index) =>
    import("Reducers/environment/access").then(reducer =>
      dispatch(
        reducer.updateAccess(
          props.organizationId,
          props.projectId,
          environment,
          access,
          index
        )
      )
    ),
  editLine: (index, isNew) =>
    import("Reducers/environment/access").then(reducer =>
      dispatch(reducer.editLine(index, isNew))
    ),
  delete: access =>
    import("Reducers/environment/access").then(reducer =>
      dispatch(
        reducer.deleteAccess(
          props.organizationId,
          props.projectId,
          props.environmentId,
          access
        )
      )
    ),
  cancelUpdateAccess: () =>
    import("Reducers/environment/access").then(reducer =>
      dispatch(reducer.cancelUpdateAccess())
    )
});

const mapStateToProps = (state, props) => {
  const access = state.environmentAccess || new Map();
  const environment = state.environment || new Map();

  return {
    accesses: access.getIn(
      ["data", props.organizationId, props.projectId, props.environmentId],
      new Map()
    ),
    editedLine: access.get("editedLine"),
    isNew: access.get("isNew"),
    errors: access.get("errors", {}),
    environment: environment.getIn([
      "data",
      props.organizationId,
      props.projectId,
      props.environmentId
    ]),
    isLoading: access.get("loading"),
    isUpdateLoading: access.get("updateLoading")
  };
};

EnvironmentAccessList.propTypes = {
  loadAccesses: PropTypes.func,
  onChange: PropTypes.func,
  updateAccess: PropTypes.func,
  editLine: PropTypes.func,
  cancelUpdateAccess: PropTypes.func,
  delete: PropTypes.func,
  environment: PropTypes.object,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  accesses: PropTypes.object,
  environmentId: PropTypes.string,
  isNew: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  intl: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EnvironmentAccessList));
